<template>
  <div class="note">
    <Loading :value="loading" />
    <div class="note-header">

      <v-system-bar dark color="primary">
        <v-icon class="mr-1">mdi-pencil</v-icon>
        <small><strong class="text-uppercase">{{ $t('NOTE.FORM_TITLE') }}</strong></small>
        <v-spacer/>
        <v-icon small class="clickable" @click="closeDialog">mdi-close</v-icon>
      </v-system-bar>
      <v-toolbar flat transparent dense>
        <v-select
        :items="$t('NOTE.STATUS_ITEMS')"
        :v-model="dev_status"
        dense
        ></v-select>
        <v-select
        :items="$t('NOTE.STATUS_TYPE_ITEMS')"
        :v-model="dev_status_type"
        dense
        ></v-select>

        <v-spacer />

        <v-btn
          x-small
          fab
          depressed
          color="error"
          class="mr-4"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          depressed
          color="success"
          :disabled="!valid"
          @click="updateItem"
        >
          <v-icon class="mr-2">mdi-check</v-icon>
        </v-btn>

      </v-toolbar>
    </div>
    <div class="note-content">
      <div class="note-messages">
        <template v-for="(item, i) in dev_messages">
        <div class="note-message" :key="'nmsg'+i">
          <v-row class="ma-0" :key="'no'+i">
            <v-col cols="12">
              <small><strong>{{$t('NOTE.CREATED_AT')}}:</strong> {{ $moment.fromNow(dev_messages[i].created_at) }}</small>
              <small><strong>{{$t('NOTE.UPDATED_AT')}}:</strong> {{ $moment.fromNow(dev_messages[i].created_at) }}</small>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12">
              <v-text-field v-model="dev_messages[i].title"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="12">
              <v-text-field v-model="dev_messages[i].description"></v-text-field>
            </v-col>
          </v-row>
        </div>
        </template>
      </div>
    </div>
  </div>

</template>

<script>

import Loading from '@/components/Loading'


export default {
  name: 'Note',
  data: () => ({
    error: '',
    loading: false,
    dev_status: '',
    dev_status_type: '',
    dev_messages: []
  }),
  props: {
    api: {
      type: [String],
      default: ''
    },
    element: {
      type: [String],
      default: ''
    },
    element_id: {
      type: [String],
      default: ''
    },
  },
  created () {
    if (!this.api || !this.element || !this.element_id) {
      this.error = 'NOTE.MISSING_INFO'
    }
  },
  methods: {


    loadItem () {
      this.loading = true
      [this.api][this.element].search({
        where: {id: this.element_id},
        options: {limit:1, attributes: ['dev_status','dev_status_type','dev_messages']}
      })
      .then(response => {
        if (response.data) {
          this.dev_status = response.data.dev_status
          this.dev_status_type = response.data.dev_status_type
          this.dev_messages = response.data.dev_messages
        } else {
          this.error = 'NOTE.INVALID_DATA'
        }
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading = false
      })
    },


    updateItem () {
      this.loading = true
      [this.api][this.element].update({
        values: {
          dev_status: this.dev_status,
          dev_status_type: this.dev_status_type,
          dev_messages: this.dev_messages,
        },
        where: {id: this.element_id}
      })
      .then(response => {
        this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
      })
      .catch(e => {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      })
      .finally(() => {
        this.loading = false
      })
    },

  }
}




</script>
